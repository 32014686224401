import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "react-seo-component";
import { useSiteMetadata } from "../hooks/useSiteMetadata";
import PostListWithImage from "../components/PostListWithImage";
import { getRandomPosts } from "../shared/utils/postHelpers";

export default ({ data }) => {
  const {
    description,
    title,
    siteUrl,
    siteLanguage,
    siteLocale,
    twitterUsername,
    authorName,
    image
  } = useSiteMetadata();
  const classPrefix = "404";
  const blogPosts = data.allMdx.nodes;

  return (
    <React.Fragment>
      <Layout>
        <SEO
          title={title}
          description={description}
          pathname={siteUrl}
          siteLanguage={siteLanguage}
          siteLocale={siteLocale}
          twitterUsername={twitterUsername}
          authorName={authorName}
          author={authorName}
          defaultImage={image}
        />
        <Div className={classPrefix}>
          <h1 className={`${classPrefix}__header`}>
            Are you lost?{" "}
            <span role="img" aria-label="emoji-with-monocle">
              🧐
            </span>
          </h1>
          Maybe you were looking for one of the blog posts below:
        </Div>

        <PostListWithImage
          posts={getRandomPosts(blogPosts, 6)}
        ></PostListWithImage>
      </Layout>
    </React.Fragment>
  );
};

const Div = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
`;

export const query = graphql`
  query SITE_404_QUERY {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { eq: true }, tags: { ne: "logs" } } }
    ) {
      nodes {
        id
        frontmatter {
          title
          date
          icon
          tags
          description
          cover {
            publicURL
          }
        }
        fields {
          slug
        }
        timeToRead
      }
    }
  }
`;
